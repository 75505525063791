.approve-btn {
	color: rgb(0 147 67);

	:hover {
		color: #87ffbe !important;
	}
	:active {
		color: #00ff75 !important;
	}
}
.reject-btn {
	color: #ff4f4f;

	:hover {
		color: #ff8c8c !important;
	}
	:active {
		color: #ff4f4f !important;
	}
}

// Ignore default hover and active for antd button with type "link"
.approve-btn.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
	color: #87ffbe;
}
.approve-btn.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
	color: #00ff75;
}
.reject-btn.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
	color: #ff4f4f;
}
.reject-btn.ant-btn-link:not(:disabled):not(.ant-btn-disabled):active {
	color: #ff8c8c;
}

.ant-dropdown {
	background-color: var(--theme-main-bg-color); // Custom background color
	color: var(--theme-main-text-color); // Custom text color
	border-radius: 8px;

	.ant-dropdown-menu {
		background-color: var(--theme-main-bg-color);

		.ant-dropdown-menu-item {
			color: var(--dropdown-hover-text-color);

			&:hover {
				background-color: rgba(123, 123, 123, 0.3);
			}
		}
	}
}

.generic-container.well-delivered-page {
	.title {
		font-size: 20px;
		font-weight: 600;
		color: var(--theme-main-text-color);
	}

	.main-container {
		flex: 1;
		width: 100%;
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.generic-header {
			height: 50px;
			justify-content: space-between;
		}

		.generic-content {
			flex: 1;
			gap: 0px;
			max-height: 100%;
			overflow: hidden;
			flex-wrap: nowrap;

			.markdown-content {
				flex: 1;
				height: 100%;
				display: flex;
				max-width: 100%;
				overflow: auto;
				flex-direction: column;
				gap: 20px;
				padding: 30px 15px;
			}
		}
	}

	.header {
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		border-bottom: 1px solid rgba(125, 125, 125, 1);
		padding-bottom: 8px;
	}

	.paragraph {
		font-size: 14px;
		font-weight: 400;
		line-height: 25px;
		color: var(--theme-main-text-color);
		margin-top: 0;
		margin-bottom: 0;
		opacity: 0.85;
	}

	.unordered-list,
	.ordered-list {
		padding-inline-start: 20px;
		font-size: 14px;
		font-weight: 400;
		color: var(--theme-main-text-color);
		line-height: 25px;
		margin-top: 0;
		margin-bottom: 0;
		opacity: 0.85;
	}
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item,
.ant-select-multiple .ant-select-selection-item {
	border-radius: 2px;
	border: var(--input-border-color);
	background: rgba(113, 113, 113, 0.5);
	color: var(--theme-main-text-color);
	opacity: 0.85;
}

.ant-select-dropdown {
	color: var(--theme-main-text-color);
	background: var(--theme-main-bg-color);
	box-shadow: var(--box-shadow-primary), var(--box-shadow-secondary);
	border-radius: "8px";

	.ant-select-item-option-content {
		color: var(--theme-main-text-color);
	}

	.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
		background-color: transparent;
		&:hover {
			background-color: rgba(123, 123, 123, 0.3);
		}
	}

	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: rgba(123, 123, 123, 0.3);
	}
}

.ant-select-open .ant-select-selector,
.ant-select-open .ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-select-focused .ant-select-selector {
	color: var(--theme-main-text-color);
}

.ant-select-multiple .ant-select-selector {
	border-radius: 2px;
}

/* Antd Select */
.ant-select-single .ant-select-selector {
	border-radius: 2px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: transparent;
	border: var(--input-border-color);
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(
		.ant-select-customize-input
	):not(.ant-pagination-size-changer)
	.ant-select-selector,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
		.ant-pagination-size-changer
	):hover
	.ant-select-selector {
	// border-color: #299ba3;
	border-color: var(--default-btn-color);
	background-color: rgba(64, 169, 255, 0.1) !important;
}

.custom-select .ant-select-selector {
	box-shadow: none !important;
	border-color: transparent !important;
	border: 0 !important;
}

.ant-select-single .ant-select-selector {
	color: var(--theme-main-text-color);
}

.ant-select .ant-select-arrow {
	color: var(--theme-main-text-color);
}

.ant-select-single.ant-select-open .ant-select-selection-item {
	color: var(--theme-main-text-color);
	opacity: 0.25;
}

.ant-select {
	&:hover {
		color: var(--theme-main-text-color);

		.ant-select-clear {
			background: var(--theme-main-bg-color);
			color: rgba(123, 123, 123, 0.5);
		}
	}
}

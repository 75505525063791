.context-menu {
	width: 200px;
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: var(--theme-main-bg-color);
	padding: 5px 3px;
	margin: 0;
	z-index: 1000;
	font-size: 14px !important;
	border-radius: 5px;
	box-shadow: 0px 4px 4px 0px #00000040;

	a {
		cursor: pointer;
		padding: 10px;
		align-items: center;
		color: var(--theme-main-text-color);
		font-size: 14px;
	}

	a:hover {
		cursor: pointer;
		padding: 10px;
		background-color: var(--theme-main-hover-color);
		color: var(--theme-main-hover-text-color);
	}
}

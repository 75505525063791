// Default button
.ant-btn-default {
	border-radius: 2px;
	background-color: var(--input-bg-color);
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

	&:not(:disabled):not(.ant-btn-disabled):hover {
		color: var(--default-btn-color);
		border-color: var(--default-btn-color);
		opacity: 0.8;
	}

	&:disabled {
		color: var(--input-color-disabled);
		border: 1px solid var(--input-border-disabled);
		background-color: var(--input-bg-disabled);
		opacity: 1;
	}

	// Danger Button
	&.ant-btn-dangerous {
		color: var(--theme-main-text-color);
		border: 1px solid var(--input-border-disabled);

		&:not(:disabled):not(.ant-btn-disabled):hover {
			color: var(--danger-btn-color);
			border-color: var(--danger-btn-color);
			opacity: 0.8;
		}

		&:disabled {
			color: var(--input-color-disabled);
			background-color: var(--input-bg-disabled);
			opacity: 1;
		}
	}

	// Ghost Button
	&.ant-btn-background-ghost {
		color: var(--theme-main-text-color);
		border-color: var(--help-center-search-text-color);

		&:not(:disabled):not(.ant-btn-disabled):hover {
			color: var(--default-btn-color);
			border-color: var(--default-btn-color);
			background: transparent;
		}
	}
}

// Primary button
.ant-btn-primary {
	background-color: var(--default-btn-color);
	color: var(--secondary-color);
	border: 1px solid var(--default-btn-color);
	border-radius: 2px;
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

	&:not(:disabled):not(.ant-btn-disabled) {
		background-color: var(--default-btn-color);
		color: var(--secondary-color);

		&:hover {
			color: var(--secondary-color);
			opacity: 0.8;
		}

		&.timewriting-submitted {
			background-color: var(--input-bg-disabled);
			color: #00bebe;
		}
	}

	&:disabled {
		color: var(--input-color-disabled);
		border: 1px solid var(--input-border-disabled);
		background-color: var(--input-bg-disabled);
		opacity: 1;
	}
}

// Custom button for timewriting software
.ant-btn-software {
	background-color: #b646a7;
	color: var(--secondary-color);
	border: 1px solid #b646a7;
	border-radius: 2px;
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

	&:not(:disabled):not(.ant-btn-disabled) {
		background-color: #b646a7;
		color: var(--secondary-color);

		&:hover {
			background-color: #E897DD;
			color: var(--secondary-color);
			border: 1px solid #b646a7;
			opacity: 0.8;
		}

		&.timewriting-submitted {
			background-color: var(--input-bg-disabled);
			color: #b646a7;
		}
	}
}

.ant-btn-link {
	color: var(--default-btn-color);
}

.custom-form-btn {
	color: var(--default-btn-color);
}

.disabled-custom-form-btn {
	color: var(--divider-border-color);
}

.help-center {
	gap: 10px;
	background: var(--help-center-bg);
	overflow: auto;
	position: relative;

	&.show-modal {
		width: 70%;
	}

	&.hide-modal {
		width: 100%;
	}

	.side-container-button {
		position: absolute;
		top: auto;
		right: 30px;

		&.show-modal {
			display: none;
		}
	}

	.help-center-generic-content {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 30px;
		margin: 40px 120px;
		color: var(--theme-main-text-color);

		.ant-divider {
			.ant-divider-inner-text {
				color: var(--help-center-search-text-color);
			}
		}

		.ant-divider-with-text::before {
			border-block-start-color: var(--help-center-search-text-color);
		}

		.ant-divider-with-text::after {
			border-block-start-color: var(--help-center-search-text-color);
		}

		.help-center-header {
			font-size: 20px;
			font-weight: 600;
			line-height: 25px;
		}

		.search-input {
			background-color: var(--help-center-search-bg-color) !important;
			color: var(--help-center-search-text-color) !important;
		}

		::placeholder {
			color: var(--help-center-search-text-color) !important;
		}

		// button {
		// 	color: #000;
		// }
	}
}

.help-center-side-container {
	position: absolute;
	height: 100%;
	width: 30%;
	top: 0;
	right: 0;
	z-index: 999;

	&.show-modal {
		display: block;
	}

	&.hide-modal {
		display: none;
	}

	.side-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		background: rgba(31, 31, 31, 0.85);

		.generic-header {
			height: 50px;
			justify-content: space-between;
		}

		.generic-content {
			display: flex;
			flex: 1;
			flex-direction: column;
			max-height: 100%;
			overflow: auto;
			// background: linear-gradient(
			// 	180deg,
			// 	rgba(0, 0, 0, 0.5) 0%,
			// 	rgba(8, 124, 133, 0.5) 100%
			// );
		}

		.generic-footer {
			height: 50px;
			border-radius: 0px;
		}
	}

	.side-container-close-button {
		color: var(--default-btn-color);
	}
}

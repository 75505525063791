.well-overview {
	&.generic-container {
		gap: 20px;
		overflow: auto;
	}

	.main-container {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.project-details-container {
		display: flex;
		flex-wrap: inherit;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		padding: 30px;
		gap: 0;
		overflow: auto;
		max-height: 100%;

		.well-project-details-content {
			display: flex;
			flex-direction: column;
			max-width: 30%;
			height: 100%;
			position: relative;
			gap: 20px;

			.well-project-details-buttons {
				margin-top: auto;
				display: flex;
				flex-direction: row;
				gap: 10px;
			}
		}
	}

	.phase-progress-bar-bg {
		width: 100%;
		background: rgba(123, 123, 123, 0.2);
		height: 46px;
		color: var(--progress-bar-text-color);
		border-radius: 3px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;

		.phase-progress-bar {
			left: 0;
			position: absolute;
			height: 46px;
			border-radius: 3px;
			background: var(--progress-bar-color);
			// background: linear-gradient(
			// 	90deg,
			// 	#225c61 0%,
			// 	#299ba3 100%
			// ); //terra original color
		}
	}

	.phase-container {
		min-width: 320px;
		max-width: 320px;
		flex: 1;
		height: 100px;

		.phase-item {
			display: flex;
			flex-direction: row;
			align-items: center;

			> span:hover {
				cursor: pointer;
				color: #7cb9bd;
			}
		}

		.phase-item-disabled {
			border-top: 0px;
			background: rgba(95, 95, 95, 0.7);
		}

		.task-container {
			padding: 0;
			gap: 0;

			.task-item {
				cursor: pointer;
				padding: 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				align-self: stretch;
				border-bottom: 1px solid rgba(128, 128, 128, 0.5);

				span {
					// color: rgba(255, 255, 255, 0.85);
					color: var(--theme-main-text-color);
					opacity: 0.85;
				}

				&:hover {
					// background-color: rgba(255, 255, 255, 0.1);
					// color: #7cb9bd;
					background-color: rgba(128, 128, 128, 0.5);
				}
			}

			.task-item-disabled {
				background-color: rgba(56, 55, 55, 0.5);
				filter: grayscale(1);
			}
		}

		.task-container-disabled {
			background-color: rgba(56, 55, 55, 0.5);
		}
	}
}

.extra-panel-container {
	display: flex;
	flex-wrap: wrap;
	background: rgba(123, 123, 123, 0.1);
	padding: 20px;
	gap: 20px;
}

.tenant-module-config-modal {
	width: 70vw !important;
}

.ant-modal {
	color: var(--theme-main-text-color);

	.ant-modal-title {
		width: 100%;
		color: var(--primary-text);
	}

	.ant-modal-content {
		background-color: var(--theme-main-bg-color);
		padding: 0;

		.ant-modal-header {
			display: flex;
			padding: 12px 30px;
			margin-bottom: 0;
			align-items: center;
			gap: 10px;
			align-self: stretch;
			border-radius: 3px 3px 0px 0px;
			box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
				0px 1px 10px 0px rgba(0, 0, 0, 0.12);
			font-weight: 600;
			background-color: var(--primary-color);
			color: var(--primary-text);
		}

		.ant-modal-body {
			padding: 15px;
			overflow: auto;
		}

		.ant-modal-footer {
			display: flex;
			padding: 10px 16px;
			justify-content: flex-end;
			align-items: center;
			gap: 20px;
			align-self: stretch;
			border-radius: 0px 0px 3px 3px;
			background: var(--container-footer-bg-color);
			margin-top: 0;

			.ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
				margin: 0;
			}
		}
	}
}

.modal-generic-header {
	display: flex;
	padding: 12px 16px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-radius: 3px 3px 0px 0px;
	background: var(--primary-color);
	box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.modal-generic-footer {
	display: flex;
	padding: 10px 16px;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	align-self: stretch;
	border-radius: 0px 0px 3px 3px;
	background: var(--modal-footer-color);
}

.profile-picture-edit {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}

.profile-picture-hover {
	display: flex;
	position: absolute;
	background: rgba(3, 24, 26, 0.77);
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.profile-picture-initials {
	height: 100%;
	width: 100%;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--primary-color);
	color: var(--primary-text);
}

.profile-initials {
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px; /* 93.333% */
}

.light-dark-mode-radio {
	display: flex;
	gap: 20px;

	.light-dark-mode-radio-button {
		padding: 20px;
		border-radius: 5px;
		height: auto;
		border: 1px solid #424242;
		background-color: transparent;
		color: var(--container-text-color);

		&:hover {
			background-color: rgba(128, 128, 128, 0.3);
			color: var(--default-btn-color);
			border: 2px solid var(--default-btn-color);
		}
	}

	.ant-radio-button-wrapper-checked.light-dark-mode-radio-button {
		border: 3px solid var(--default-btn-color);
	}

	.light-dark-mode-radio-button::before {
		display: none;
	}
}

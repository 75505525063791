body {
	margin: 0;
	font-family: "CustomFont", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* AeonikPro - Main Font | Sans-Serif - Backup Font */
/* body {
	font-family: "AeonikPro", sans-serif !important;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */

/* Apply this font family onto texts wrapped within the <code> tag */

/* @font-face {
	font-family: "CustomFont";
	src: url("http://localhost:5000/api/fonts/CustomFont.woff2") format("woff2");
} */

/* body {
	font-family: "CustomFont", sans-serif;
	margin: 0;
} */

/* Aeonik Pro Font Files Setup */
/* @font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-Regular.otf") format("opentype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-Bold.otf") format("opentype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-BoldItalic.otf") format("opentype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-LightItalic.otf") format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "AeonikPro";
	src: url("./assets/fonts/AeonikPro-RegularItalic.otf") format("opentype");
	font-weight: normal;
	font-style: italic;
} */
/* 
	By specifying properties like font-weight and font-style in each @font-face declaration, you're essentially naming each variant of the font. 
	This helps the browser recognize and use the correct version when you apply styles later on in your CSS. 
*/

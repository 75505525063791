.ant-input-number {
	width: 100%;
	border: var(--input-border-color);

	&.ant-input-number-outlined {
		&:focus,
		&:focus-within,
		&:hover {
			border-color: var(--default-btn-color);
		}
	}
}

.ant-input-number-outlined.ant-input-number-disabled input[disabled],
.ant-input-number-outlined[disabled] input[disabled], 
.ant-input-number-outlined.ant-input-number-disabled textarea[disabled], 
.ant-input-number-outlined[disabled] textarea[disabled]  {
	border: var(--input-border-color);
}
.ant-form {
	color: var(--theme-main-text-color);

	.horizontal-label {
		.ant-form-item-label {
			padding: 0px;
		}
		.ant-form-item-control {
			width: auto;
		}

		.ant-form-item-row {
			display: flex;
			flex-direction: row !important;
			align-items: center;
		}
	}

	// .ant-form-item {
	// 	.ant-form-item-label {
	// 		> label {
	// 			.ant-form-item-tooltip {
	// 				// color: var(--theme-main-text-color);
	// 				opacity: 0.6;
	// 			}
	// 		}
	// 	}
	// }

	// .ant-form-item-label {
	// 	> label {
	// 		.ant-form-item-optional {
	// 			// color: var(--theme-main-text-color);
	// 			opacity: 0.6;
	// 		}
	// 	}
	// }

	.color-picker {
		.ant-form-item-label {
			width: 150px;
		}
		.ant-form-item-control {
			width: auto !important;
		}
	}
}

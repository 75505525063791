.ant-color-picker-trigger {
	background: transparent;
	border-radius: 2px;
	border: var(--input-border-color);

	.ant-color-picker-color-block {
		.ant-color-picker-color-block-inner {
			border: 1px solid rgba (var(--theme-main-text-color), 0.2);
			border-radius: 4px;
		}
	}

	.ant-color-picker-trigger-text {
		color: var(--theme-main-text-color);
	}
}

.checkbox-dropdown {
	color: var(--ribbon-text-color);
}

.checkbox-dropdown-button {
	border: none;
	box-shadow: none;
	background: transparent;
	display: flex;
	align-items: center;
}

.checkbox-dropdown-footer {
	padding: 8px;
	border-top: 1px solid #ccc;
	text-align: right;
	background: var(--checkbox-dropdown-bg-color);

	button {
		color: var(--checkbox-dropdown-text-color);
	}
}

.checkbox-dropdown-content {
	max-height: 200px;
	overflow-y: auto;
	background: var(--checkbox-dropdown-bg-color);
	width: 100%;
	max-width: 300px;

	.search-input {
		width: auto;
		min-width: 200px;
		max-width: 300px;
		color: var(--checkbox-dropdown-text-color);

		// input::placeholder {
		// 	color: var(--input-field-placeholder-color);
		// }
	}
}

.attachment-file-item .ant-input,
.attachment-file-item .ant-input-outlined {
	border-radius: 2px 0px 0px 2px;
	border: 1px solid #6a6a6a;
}

.attachment-file-item .ant-input,
.attachment-file-item .ant-input-outlined:hover {
	background-color: transparent;
}

.attachment-file-item .ant-input,
.attachment-file-item .ant-input-group-addon {
	border: 1px solid #6a6a6a;
	cursor: pointer;
	padding: 0;
}

.attachment-file-item .ant-input-group .ant-input-group-addon:last-child {
	border-radius: 0px 2px 2px 0px;
}

.deliverable-item-disabled {
	color: rgba(255, 255, 255, 0.25);
	filter: grayscale(1);
}

.kanban-detail-grid-container .kanban-detail-pie {
	background: rgba(255, 255, 255, 0.15);
}

.kanban-detail-grid-container .kanban-detail-pie > div {
	margin: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.35);
}

.kanban-detail-grid-container > .kanban-detail-grid-body-item {
	padding: 10px 0px;
	margin: 0px 20px 0px 0px;
}

.kanban-detail-header {
	padding-bottom: 10px;
	margin: 0px 20px 0 0px;
	background: rgba(255, 255, 255, 0.15);
	border-radius: 5px 5px 0px 0px;
}

.kanban-detail-header > div {
	display: flex;
	padding: 12px 20px;

	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 5px 5px 0px 0px;
	border-top: 1px solid #299ba3;
	background: linear-gradient(180deg, #134f53 0%, rgba(6, 45, 48, 0.58) 100%);
	box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.project-add-button {
	display: flex;
	// padding: 4px 15px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 2px;
	border: 1px dashed var(--neutral-5, #d9d9d9);
	// background: #062d30;
	background: var(--primary-color);
	color: var(--primary-text);

	/* drop-shadow/button-secondary */
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.project-active-button {
	border-radius: 2px;
	background: rgba(255, 255, 255, 0.25);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.project-form {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-template-areas:
		"a a a a a a a a"
		"c c c c c c c c"
		"d d d d e e e e"
		"f f f f g g g g"
		"j j j j j j j j"
		"k k k k k k k k";
}

.project-overview-container {
	// background: linear-gradient(
	// 	180deg,
	// 	rgba(0, 27, 29, 0.85) 0%,
	// 	rgba(51, 90, 92, 0.85) 100%
	// );
	/* overflow-x: auto; */
	background: var(--container-inside-bg-color);
}

.project-status-container {
	height: 100%;
	min-width: 400px;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.15);
}

.project-status-container-header {
	width: 100%;
	padding: 12px 20px;
	gap: 3px;
	align-self: "stretch";
	border-radius: 5px 5px 0px 0px;
	background: var(--secondary-color);
	color: var(--primary-text);
	box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);

	display: flex;
	flex-direction: row;
	align-items: center;
}

.project-status-container-content {
	width: 100%;
	flex: 1;
	display: flex;
	padding: 20px 40px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	align-self: stretch;
	overflow: auto;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	// border: 1px solid black;
}

.title-selector .ant-select-selector {
	box-shadow: none !important;
	border-color: transparent !important;
	border: 0 !important;
	padding-right: 20px !important;
}

.title-selector.ant-select .ant-select-selector .ant-select-selection-item {
	// font-family: "Poppins", sans-serif !important;
	font-size: 20px;
	line-height: 25px;
	font-weight: 600;
}

.test {
	display: flex;
	padding: 4px 15px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 2px;
}

.ellipsis-label {
	margin-top: 6px;
	display: inline-block;
	/* white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */
	max-width: 200px; /* Adjust as needed */
}

.deliverable-status-button {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	border-radius: 2px;
	box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.ant-btn-primary {
	&.mark-as-in-progress-button:not(:disabled):not(.ant-btn-disabled) {
		border: 1px solid #0f709e;
		background: #0f709e;
		color: getTextColor(#0f709e);

		&:hover {
			opacity: 1;
			border: 1px solid darken(#0f709e, 10%);
			background: darken(#0f709e, 10%);
			color: getTextColor(#0f709e);
		}
	}

	&.submit-for-review-button:not(:disabled):not(.ant-btn-disabled) {
		border: 1px solid #169092;
		background: #169092;
		color: getTextColor(#169092);

		&:hover {
			opacity: 1;
			border: 1px solid darken(#169092, 10%);
			background: darken(#169092, 10%);
			color: getTextColor(#169092);
		}
	}

	&.mark-as-complete-button:not(:disabled):not(.ant-btn-disabled) {
		border: 1px solid #237d4d;
		background: #237d4d;
		color: getTextColor(#237d4d);

		&:hover {
			opacity: 1;
			border: 1px solid darken(#237d4d, 10%);
			background: darken(#237d4d, 10%);
			color: getTextColor(#237d4d);
		}
	}

	&.submitted-for-review-button:not(:disabled):not(.ant-btn-disabled) {
		border: 1px solid #1f1f1f;
		background: #1f1f1f;
		color: getTextColor(#1f1f1f);

		&:hover {
			opacity: 1;
			border: 1px solid darken(#1f1f1f, 10%);
			background: darken(#1f1f1f, 10%);
			color: getTextColor(#1f1f1f);
		}
	}

	&.deliverable-status-button-disabled:not(:disabled):not(.ant-btn-disabled) {
		border: 1px solid #434343;
		background: #434343;
		color: getTextColor(#434343);

		&:hover {
			opacity: 1;
			border: 1px solid darken(#434343, 10%);
			background: darken(#434343, 10%);
			color: getTextColor(#434343);
		}
	}
}

.gallery {
	padding: 30px 86px !important;
	width: 100%;

	.gallery-container {
		display: grid;
		grid-template-columns: repeat(4, minmax(22%, 1fr));
		width: 100%;
		gap: 40px;

		.add-new-item-div {
			align-self: flex-start;
			height: 125px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

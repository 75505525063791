.ant-picker-outlined.ant-picker-disabled,
.ant-picker-outlined[disabled],
.ant-picker.ant-picker-disabled,
.ant-picker[disabled] {
	background-color: transparent;
}

.ant-picker-outlined.ant-picker-disabled:hover:not([disabled]),
.ant-picker-outlined[disabled]:hover:not([disabled]),
.ant-picker.ant-picker-disabled:hover:not([disabled]),
.ant-picker[disabled]:hover:not([disabled]) {
	background-color: transparent;
}

// .ant-popover {
// 	--antd-arrow-background-color: #393939;
// }

// .ant-input-affix-wrapper .ant-input-prefix {
// 	// color: rgba(255, 255, 255, 0.45);
// 	// color: var(--input-field-text-color);
// }

.ant-layout-content.site-content {
	background: var(--theme-main-bg-color);
	color: var(--theme-main-text-color);
}

// .ant-alert-message,
// .ant-alert-description {
// 	font-family: "Poppins", sans-serif !important;
// }

.ant-form-item {
	margin-bottom: 10px;
	// font-family: "Poppins", sans-serif !important;
}

// .ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
// }

.ant-upload-wrapper {
	color: var(--theme-main-text-color);

	.ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon,
	.ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon {
		color: var(--theme-main-text-color);
	}
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
	display: flex;
	justify-content: center;
	width: 128px;
	height: 128px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
	.ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
	.ant-upload.ant-upload-select {
	width: 100%;
	height: 100%;
	margin-inline-end: 0;
	margin-bottom: 0;
	border: none;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper
	.ant-upload.ant-upload-select
	> .ant-upload,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
	.ant-upload.ant-upload-select
	> .ant-upload {
	overflow: hidden;
	border-radius: 50%;
}

// .ant-modal .ant-modal-content {
// 	border-radius: 0;
// 	padding: 0;
// }

// .ant-modal .ant-modal-body {
// 	padding: 10px 15px 20px 15px;
// }

// .ant-modal .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
// 	margin: 0;
// }

.ant-spin-nested-loading .ant-spin-container {
	display: flex;
	flex-direction: column;
}

/* Antd Input */

.ant-picker-outlined,
.ant-picker {
	background-color: transparent;
	width: 100%;
}

// .input-clearable {
// 	border-radius: 2px;
// 	border: 1px solid rgba(255, 255, 255, 0.35);
// }

// .input-clearable:focus,
.ant-picker-outlined:focus-within,
.ant-picker-outlined:hover,
.ant-picker-outlined:focus,
.ant-picker:focus-within,
.ant-picker:hover,
.ant-picker:focus
// .ant-radio-button-wrapper:hover,
// .ant-radio-button-wrapper-checked,
// .ant-radio-button-wrapper .ant-radio-button-wrapper-in-form-item
{
	border-color: #299ba3;
	background-color: rgba(64, 169, 255, 0.1);
}

// .antd-radio-button .input-clearable {
// 	background-color: transparent !important;
// }

span.ant-tag.ant-tag-has-color,
span.ant-tag.ant-tag-has-color.clickable-text {
	cursor: pointer;
}

.ant-spin-nested-loading > div > .ant-spin {
	max-height: inherit;
}

// .ant-popover .ant-popover-inner {
// 	background-color: #393939;
// }

.custom-checkbox div.ant-typography {
	margin-bottom: 0;
	padding: 5px 0;
}

.ant-collapse-header .ant-collapse-header-text {
	flex: none !important;
}

.ant-collapse-header .ant-collapse-expand-icon {
	flex: auto !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
	padding: 0px 0px !important;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
	padding: 5px 0px !important;
}

.ant-form-item .ant-form-item-label > label {
	color: var(--container-text-color);
}

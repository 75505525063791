.main-sider .ant-menu-item,
.main-sider .ant-menu .ant-menu-submenu-title {
	border-radius: 0;
	color: var(--primary-text);
}

.main-sider .ant-menu {
	background-color: initial;
}

.main-sider .ant-menu-item:hover{
	color: #ffffff !important;
}

.main-sider .ant-menu-item-selected,
.main-sider .ant-menu-item-selected:active,
.ant-menu-light:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
	> .ant-menu
	.ant-menu-submenu-selected
	> .ant-menu-submenu-title,
.ant-menu-light:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-light
	> .ant-menu:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):hover {
	background-color: rgba(135, 135, 135, 0.4);
	color: var(--primary-text);
}

// Settings Button
.ant-menu-light .ant-menu-submenu-title:hover {
	color: #ffffff !important;
}

.ant-menu-light
	> .ant-menu:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-light:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-light
	> .ant-menu:not(.ant-menu-horizontal)
	.ant-menu-item:not(.ant-menu-item-selected):active {
	background-color: rgba(135, 135, 135, 0.4);
	color: var(--theme-main-text-color);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected,
.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
	background-color: rgba(135, 135, 135, 0.4);
	color: #ffffff;
}

.ant-menu-submenu-open {
	background-color: rgba(135, 135, 135, 0.4);
}

.main-sider .ant-menu-item:active {
	background-color: rgba(135, 135, 135, 0.4);
	color: var(--theme-main-text-color);
}

.main-sider .ant-menu.ant-menu-inline,
.main-sider .ant-menu.ant-menu-vertical {
	border-inline-end: 0;
}

.main-sider .ant-menu.ant-menu-vertical {
	display: flex;
	padding: 10px 15px;
	flex-direction: column;
	align-items: center;
}

.main-sider .ant-menu-submenu {
	width: 100%;
	border-radius: 0;
}

.main-sider.ant-layout-sider-collapsed {
	max-width: 74px !important;
	min-width: 74px !important;
}

.main-sider.ant-layout-sider-collapsed .ant-layout-sider-trigger {
	max-width: 74px !important;
	min-width: 74px !important;
}

.main-sider .ant-layout-sider-children {
	display: flex;
	flex-direction: column;
}

.ant-layout .main-sider.ant-layout-sider {
	z-index: 402;
	// Elemental Theme v1.0
	// background: var(--primary-color);
	// Elemental Theme v2.0
	background-color: var(--secondary-color);
	background: var(--primary-color);
	color: var(--primary-text);
	border-right: 0.5px;
	box-shadow: 0px 4px 4px 0px #00000040;
}

.ant-layout .main-sider .ant-layout-sider-trigger {
	background: var(--primary-color);
	color: var(--primary-text);
}

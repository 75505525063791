.ant-progress {
	color: var(--project-cards-text-color);

	.ant-progress-text {
		color: var(--project-cards-text-color);
	}

	.ant-progress-bg {
		background: var(--default-btn-color);
	}

	.ant-progress-inner {
		background: var(--progress-inner-bg);
	}
}

.crud-tab-table-modal-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 20px;
	overflow: hidden;
	max-height: 100%;

	.main-container {
		width: 70%;
		max-height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		border-radius: 3px;
		//background: rgba(31, 31, 31, 0.85); // follow theme

		.generic-header {
			height: 50px;
		}

		.generic-content {
			flex: 1;
			gap: 0px;
			max-height: 100%;
			overflow: hidden;
			flex-wrap: nowrap;

			.generic-table-container {
				flex: 1;
				height: 100%;
				display: flex;
				max-width: 100%;
				overflow: hidden;
				flex-direction: column;

				.generic-table-header {
					display: flex;
					flex-direction: row;

					.table-header-content {
						margin-right: auto;
						min-height: 32px;
						display: flex;
						align-items: center;
					}
				}

				.table-content {
					flex: 1;
					max-height: 100%;
					overflow: hidden;
				}
			}
		}
	}

	.side-container {
		display: flex;
		flex: 1;
		flex-direction: column;
		border-radius: 3px;
		background: var(--side-container-bg);
	}
}

.ant-table-wrapper {
	&.audit-log-table {
		background-color: transparent;
		height: min-content;
		padding: 0;
		color: var(--theme-main-text-color);

		.ant-empty-normal {
			.ant-empty-description {
				color: var(--theme-main-text-color);
			}
			.ant-empty-image {
				mix-blend-mode: exclusion;
			}
		}

		.ant-table.ant-table-bordered {
			box-shadow: 4px 0 6px rgba(0, 0, 0, 0.15); // Shadow appears only on the right

			> .ant-table-container {
				border-inline-start: none;
				border-top: none;

				> .ant-table-content {
					> table {
						> thead {
							> tr {
								> th {
									border-inline-end: var(--audit-table-border);

									&:first-child {
										border-inline-start: 0px;
									}

									&:last-child {
										border-inline-end: 0px;
									}
								}

								> td {
									border-inline-end: var(--audit-table-border);
								}
							}
						}

						> tbody {
							background: rgba(255, 255, 255, 0.1);
							> tr {
								> td {
									border-inline-end: var(--audit-table-border);
									&:first-child {
										border-inline-start: var(--audit-table-border);
									}

									&:last-child {
										border-inline-end: var(--audit-table-border);
									}
								}
							}
							> tr.ant-table-placeholder {
								> td {
									border-bottom: none;
								}
							}
						}
					}
				}
			}
		}

		.ant-btn {
			background-color: transparent;
			height: min-content;
			padding: 0;
			color: var(--primary-text);

			&:hover {
				color: var(--default-btn-color);
			}
		}

		.ant-table-thead {
			> tr {
				> th,
				td {
					background: var(--primary-color) !important;
					color: var(--primary-text) !important;
					font-weight: 500;
					padding: 11px 16px;
					border-bottom: none;
				}
			}
		}

		.ant-table-column-sorter {
			color: var(--primary-text);
		}

		.ant-table-tbody {
			.ant-table-row {
				> .ant-table-cell-row-hover {
					background-color: rgba(123, 123, 123, 0.3);
				}
			}
			> tr {
				> td {
					border-bottom: var(--audit-table-border);
				}
			}
			> tr.ant-table-placeholder {
				background: transparent;

				&:hover {
					> td {
						background: transparent;
					}
				}
			}
		}

		// .ant-table-pagination.ant-pagination {
		// 	border-radius: 0px 0px 3px 3px;
		// 	margin: 0;
		// 	padding: 16px 0;
		// 	background: var(--generic-table-footer-bg-color); // #001516;
		// }

		.ant-spin-nested-loading,
		.ant-spin-container,
		.ant-table-container,
		.ant-table-content {
			height: 100%;
			text-align: left;
		}

		.ant-spin-nested-loading {
			> div {
				.ant-spin {
					max-height: inherit;
				}
			}
		}

		.ant-pagination {
			.ant-pagination-item-active {
				background-color: transparent;
				border-radius: 2px;
				border: 1px solid var(--default-btn-color);

				a {
					color: var(--default-btn-color);

					&:hover {
						color: var(--default-btn-color);
					}
				}
			}
		}
	}
}

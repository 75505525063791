.time-entry-modal.ant-popover {
	.ant-popover-arrow {
		&::before {
			background: var(--timewriting-table-container-bg);
		}
	}

	.ant-popover-content {
		--antd-arrow-background-color: var(--timewriting-table-container-bg);
		.ant-popover-inner {
			background-color: var(--timewriting-table-container-bg);
		}
	}
}

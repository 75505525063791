/**
 * @author Firstname Lastname
 * TODO: Cleanup all the scss files grouping them into modules
 * Example:
 * 1. All custom buttons related styling should be put into the CustomButton.scss file (example: approve-btn, reject-btn)
 */

@import "leaflet/dist/leaflet.css";
@import "./assets/scss/_theme.scss";
@import "./assets/scss/_functions.scss";
@import "./assets/scss/antd.scss";
@import "./assets/scss/auditlog.scss";
@import "./assets/scss/ContextMenu.scss";
@import "./assets/scss/loginPage.scss";
@import "./assets/scss/overview.scss";
@import "./assets/scss/pieChart.scss";
@import "./assets/scss/projects.scss";
@import "./assets/scss/TaskOverview.scss";
@import "./assets/scss/BreadcrumbComponent.scss";
@import "./assets/scss/CheckboxDropdown.scss";
@import "./assets/scss/tag.scss";
@import "./assets/scss/Profile.scss";
@import "./assets/scss/HelpCenter.scss";
@import "./assets/scss/CRUDTabTableModal.scss";
@import "./assets/scss/ProjectCards.scss";
@import "./assets/scss/KanbanBoard.scss";
@import "./assets/scss/generalContainers.scss";
@import "./assets/scss/WellOverview.scss";
@import "./assets/scss/ProjectSettings.scss";
@import "./assets/scss/CustomForm.scss";
@import "./assets/scss/WellDelivered.scss";
@import "./assets/scss/Modals.scss";
@import "./assets/scss/TenantSettings.scss";
@import "./assets/scss/WellDetails.scss";
@import "./assets/scss/Gallery.scss";
@import "./assets/scss/Timewriting.scss";
@import "./assets/scss/TimesheetManagement.scss";

@import "./assets/scss/genericClass.scss";

@import "./assets/scss/TextEditor.scss";

// ant components
@import "./assets/scss/antd/antTooltip.scss";
@import "./assets/scss/antd/antInputNumber.scss";
@import "./assets/scss/antd/antTabs.scss";

// Buttons
@import "./assets/scss/antd/antButton.scss";
@import "./assets/scss/CustomButton.scss";

// Popconfirm
@import "./assets/scss/TimeEntryPopconfirm.scss";
@import "./assets/scss/antd/antPopconfirm.scss";

@import "./assets/scss/antd/antDrawer.scss";
@import "./assets/scss/antd/antForm.scss";
@import "./assets/scss/antd/antDropdown.scss";
@import "./assets/scss/antd/antProgress.scss";
@import "./assets/scss/antd/antCheckbox.scss";
@import "./assets/scss/antd/antSelect.scss";
@import "./assets/scss/antd/antCollapse.scss";
@import "./assets/scss/antd/antInput.scss";
@import "./assets/scss/antd/antRadio.scss";
@import "./assets/scss/antd/antDatePicker.scss";
@import "./assets/scss/antd/antModal.scss";
@import "./assets/scss/antd/antColorPicker.scss";
@import "./assets/scss/antd/antSwitch.scss";
@import "./assets/scss/antd/antAlert.scss";
@import "./assets/scss/antd/antMenu.scss";
@import "./assets/scss/antd/antDivider.scss";

@keyframes blinking {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

::-webkit-scrollbar-thumb {
	background-color: #696969; /* Color of the thumb */
	border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: transparent;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	display: block;
}

// Important for most of the containers
.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table-container,
.ant-table-content {
	height: 100%;
	text-align: left;
}

// .antd-table-row-selected,
.antd-table-group-row-selected .antd-table-cell {
	background-color: var(--table-hover-bg-color) !important;
}

.ant-table-header {
	tr {
		th {
			&.ant-table-column-has-sorters {
				font-weight: 400;
			}
			&.search-button {
				font-weight: 400;
			}
		}
	}
}

.default-btn-color {
	color: var(--default-btn-color);
}
.disabled {
	color: rgba(255, 255, 255, 0.25);
}

// Default links
a {
	color: var(--default-btn-color);

	&:hover {
		color: var(--default-btn-color);
		opacity: 0.5;
	}

	&:active {
		color: var(--default-btn-color);
	}
}

.deliverables-attachment-file-container .ant-upload {
	width: 100%;
}

.deliverables-attachment-file-container.ant-upload-wrapper .ant-upload-list {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.spin {
	-webkit-animation: spin 3s linear infinite;
	-moz-animation: spin 3s linear infinite;
	animation: spin 3s linear infinite;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

// antd Empty component
.no-data-empty {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;

	.ant-empty-image {
		width: 185px;
		height: 185px;
	}

	.ant-empty-description {
		// font-family: "Poppins", sans-serif;
		font-size: 20px;
		color: var(--theme-text-color);
	}
}

[data-theme="light"] {
	.no-data-empty {
		.ant-empty-image {
			filter: invert(1) sepia(1) saturate(5) hue-rotate(180deg);
		}
	}
}

.alert-message-box-top {
	position: absolute !important;
	top: 30px;
	left: calc(100vw - 65%);
	width: auto;
	z-index: 30000;
	max-width: 400px;
}

.alert-message-box {
	position: absolute !important;
	bottom: 30px;
	left: 1%;
	margin: auto;
	width: auto;
	z-index: 30000;
}

.alert-message-box-mobile {
	position: absolute !important;
	bottom: 30px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	z-index: 30000;
}

.addon-button {
	display: flex;
	height: 32px;
	padding: 5px 12px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 2px;
	border: 1px solid #434343;
}

// Loading Screen Background
.main-page {
	background: var(--loading-screen-bg);
	position: absolute;
	width: 100%;
	height: 100%;
}

.empty-modal,
.empty-modal .ant-modal-content .ant-modal-body,
.empty-modal .ant-modal-content {
	background-color: transparent;
	width: 0px;
	height: 0px;
	padding: 0;
	padding-bottom: 0;
}

.site-whole {
	flex-direction: row;
	height: 100%;
	overflow: hidden;
	// color: var(--theme-main-text-color);
}

.site-layout {
	flex-direction: column;
	// background: rgba(0, 0, 0, 0);
}

.site-content {
	flex: 1;
}

.site-header {
	line-height: 0px;
	height: 52px;
	padding: 10px 30px 10px 30px;
	justify-content: space-between;
	background: var(--layout-header-footer-bg);
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.5);
	filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

	.ant-breadcrumb-separator {
		color: var(--layout-header-footer-text);
	}

	a {
		color: var(--layout-header-footer-text);
	}

	a:hover {
		color: var(--default-btn-color);
	}
}

.site-footer {
	height: 36px;
	display: flex;
	flex-direction: row;
	font-size: 12px;
	background: var(--layout-header-footer-bg);
	padding: 8px 25px 8px 25px;
	justify-content: space-between;
	align-items: center;
	color: var(--layout-header-footer-text);
}

.text-clickable {
	cursor: pointer;
}

.text-clickable:hover {
	color: var(--default-btn-color);
}

.text-clickable:active {
	color: var(--default-btn-color);
}

// .tooltip {
// 	background: rgba(0, 0, 0, 0.85);
// 	--antd-arrow-background-color: rgba(0, 0, 0, 0.85);
// 	border-radius: 2px;
// }

// .tooltip.ant-popover .ant-popover-inner {
// 	background: rgba(0, 0, 0, 0.85);
// }

// .ant-modal .generic-header,
.generic-header {
	display: flex;
	padding: 12px 30px;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	border-radius: 3px 3px 0px 0px;
	box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	font-weight: 600;
	background-color: var(--primary-color);
	color: var(--primary-text);
}

.generic-content {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 30px;
	align-self: stretch;
	align-content: flex-start;
	background-color: var(--side-container-bg);
	color: var(--container-text-color);
	overflow-y: auto;
	box-shadow: 0px 2px 4px -1px rgba(20, 62, 98, 0.15),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.generic-content > * {
	padding: 13px 0px;
}

.generic-content > *:first-child {
	padding-left: 32px;
}

.generic-content > *:last-child {
	padding-right: 32px;
}

.generic-footer {
	display: flex;
	padding: 10px 16px;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	align-self: stretch;
	border-radius: 0px 0px 3px 3px;
	background-color: var(--container-footer-bg-color);
}

.generic-container {
	padding: 20px 30px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: var(--container-bg-color);
}

.generic-table-header {
	display: flex;
	padding: 12px 16px;
	justify-content: flex-end;
	gap: 10px;
	align-items: center;
	align-self: stretch;
	border-radius: 3px 3px 0px 0px;
	background: var(--generic-table-header-bg-color);
	color: var(--generic-table-header-text-color);
}

.input-form {
	display: flex;
	height: 64px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	gap: 5px;
}

.input-form-name {
	display: flex;
	padding: 5px 0px;
	align-items: flex-start;
}

.no-access-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: var(--no-access-background-color);
	gap: 40px;
}

// Notifications Button
.notification-list-icon {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 50%;
	color: #299ba3;
	background-color: var(--notification-icon-bg);
}

// Tenant Switch Button
.tenant-button {
	cursor: pointer;
	display: flex;
	height: 45px;
	padding: 4px 14px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
}

.tenant-button-active {
	cursor: pointer;
	display: flex;
	height: 45px;
	padding: 4px 14px;
	align-items: center;
	gap: 12px;
	align-self: stretch;
	border-radius: "2px";
	border: 1px solid var(--default-btn-color);
	color: var(--default-btn-color);
}

.tenant-button:hover {
	border-radius: 2px;
	background: var(--tenant-button-bg-hover);
}

.button-transparent {
	border: none;
	background-color: transparent;
	padding: 0;
	font-size: 16px;
	line-height: 1;
	color: #000; /* Customize the color if needed */
}

.popconfirm-split-buttons.ant-popconfirm .ant-popconfirm-buttons {
	display: flex;
	justify-content: space-between;
}

.reorder-list > ul {
	list-style: none;
	padding: 10px 0px;
	margin-top: 0px;
}

.reorder-list > ul > li {
	border-radius: 10px;
	margin: 10px 0px !important;
	/* width: 100%; */
	padding: 15px 18px;
	display: flex;
	/* justify-content: space-between;
	align-items: center; */
	flex-shrink: 0;
	cursor: grab;
}

.audit-log-button {
	display: flex;
	padding: 4px 10px;
	gap: 8px;
	cursor: pointer;
}

.gannt-label {
	fill: var(--theme-main-text-color);
}

.user-section:hover {
	color: var(--default-btn-color);
}

.user-section-sign-out:hover {
	color: var(--danger-btn-color);
}

/* ReorderList.tsx */
.disabled-phase-task-order {
	border: var(--input-border-color);
}

.phase-task-order {
	border: var(--input-border-color);
}

.dragging-item {
	background-color: var(--phase-task-drag-color);
}

.list-of-operations {
	border: var(--input-border-color);
}

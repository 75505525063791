.ant-switch {
	background-color: #848484;
	&.ant-switch-checked {
		background: var(--default-btn-color);

		&:hover:not(.ant-switch-disabled) {
			background: var(--default-btn-color);
			opacity: 0.7;
		}
	}
	&:hover:not(.ant-switch-disabled) {
		background: #848484;
		opacity: 0.7;
	}
}
